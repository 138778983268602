.StripeElement {
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  padding: 16px 14px;
  border-radius: 5px;
}

.StripeElement--empty {
  border: 1px solid #c72223;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #c72223;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
