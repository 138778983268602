body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
div,
a {
  font-family: "Maven Pro", sans-serif;
  color: #606060;
}
